export type EbkFinalStatus = `CONFIRMED` | `CANCELED` | undefined

export function hasAnyLeadDecision(data: object) {
    const leadDecision: boolean | undefined = data[`leadDecision`]
    const ebkFinalStatus: EbkFinalStatus = data[`ebkFinalStatus`]

    return leadDecision !== undefined || ebkFinalStatus !== undefined
}

/**
 * Returns the decision level for a lead based on the given data.
 *
 * @param {object} data - The data object containing the lead decision and EBK final status.
 * @return {number} The decision level for the lead.
 *                  - 0 if neither the lead decision nor EBK final status is provided.
 *                  - 1 if only the lead decision is provided.
 *                  - 2 if the lead decision is true but EBK final status is not provided.
 *                  - 3 if the lead decision is true and the EBK final status is 'CONFIRMED'.
 */
export function getLeadDecisionLevel(data: object): number {
    const leadDecision: boolean | undefined = data[`leadDecision`]
    const ebkFinalStatus: EbkFinalStatus = data[`ebkFinalStatus`]

    if (leadDecision === undefined && ebkFinalStatus === undefined) {
        return 0
    }

    if (leadDecision === true && !ebkFinalStatus) {
        return 2
    }
    if (leadDecision === true && ebkFinalStatus === `CONFIRMED`) {
        return 3
    }

    return 1
}
